import React from 'react'
import { LoaderContainer, Circle } from "./Loader.style"

const Loader = () => {
    return (
        <LoaderContainer>
            <Circle color="#ea4335" delay="0s" />
            <Circle color="#4285f4" delay="0.3s" />
            <Circle color="#fbbc05" delay="0.6s" />
            <Circle color="#34a853" delay="0.9s" />
        </LoaderContainer>
    )
}

export default Loader
