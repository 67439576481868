import ContactComponent from "../../components/Contact/Contact.component"
import React from 'react'

const Contact = () => {
   return (
      <>
         <ContactComponent/>  
      </>
   )
}

export default Contact

