import * as tag from "./Home.style";
import React from "react";
// eslint-disable-next-line
import PropTypes from "prop-types";
import HomeImage from "../../assets/homeImage.png";
import { Heading } from "../common/Common.components";

const HomeComponent = (props) => {
  return (
    <tag.Container>
      <Heading style={{ width: "100%" }}>30 Days of Google Cloud</Heading>
      <tag.SubContainer>
        <tag.LeftContainer>
          <tag.LeftHead>What is 30 Days of Google Cloud?</tag.LeftHead>
          <tag.LeftDesc>
            30 Days of Google Cloud program will provide you an opportunity to
            kickstart your career in cloud and get hands-on practice on Google
            Cloud - the tool that powers apps like Google Search, Gmail and
            YouTube.
            <br />
            <br />
            Along the way, you will learn & practice concepts like computing,
            application development, big data & machine learning using cloud &
            if you get stuck, you will have your "Campus Facilitators" who are
            specially trained on Google Cloud to help.
          </tag.LeftDesc>
        </tag.LeftContainer>
        <tag.RightContainer>
          <tag.RightImg src={HomeImage} />
        </tag.RightContainer>
      </tag.SubContainer>
    </tag.Container>
  );
};

HomeComponent.propTypes = {};

export default HomeComponent;
