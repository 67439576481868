import styled, { css } from "styled-components";

export const LeaderBoardContainer = styled.div`
  padding: 2rem 6.25rem 3.5rem 6.25rem;
  
  @media (max-width: 1000px){
    padding: 2rem 3rem;
  }
  @media (max-width: 520px){
    padding: 1.5rem 1rem;
  }
`;

export const Heading = styled.h1`
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: left;
  padding: 2rem 0 1.5rem;
`;

export const RecordContainer = styled.div``;

export const RecordRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 700px){
    flex-direction: column;
    align-items: center;
  }
`;

export const Column = styled.div`
  width: 33%;

  @media (max-width: 700px){
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:nth-child(2){
      margin: 1.5rem 0;
    }
  }
`;

export const RecordHeading = styled.h3`
  width: 100%;
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #0185fd;
  padding: 0 0 1.2rem;
  
  @media (max-width: 700px){
    text-align: center;
  }
`;

export const RecordItem = styled.p`
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #515151;
  padding: 0 0 0.5rem;
  text-transform: capitalize !important;
`;

export const NoDataMessage = styled.div`
  font-family: "Raleway";
  font-weight: normal;
  color: #515151;

  ${(props) => props.isLoading && css`
    padding: 2rem;
    font-weight: bold;
    text-align: center;
  `}
  
  @media (max-width: 700px){
    text-align: center;
  }
`;
