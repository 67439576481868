import FreqAskQuesComponent from "../../components/FreqAskQues/FreqAskQues.component"
import React from 'react'

const FreqAskQues = () => {
   return (
      <>
         <FreqAskQuesComponent />  
      </>
   )
}

export default FreqAskQues

