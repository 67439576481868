const questions = [
   {
     question: 'I am not able to get the Qwiklabs credits pass after enrolling in the program, what to do?',
     answer: 'You can try the following solutions: Here is a helpful video created by one of our facilitators that will tell you how to get the credits. If you have just completed the lab, then please wait for a few minutes and refresh your chrome browser tab a few times. Start the lab again wait for 5 minutes and end the lab and then check again. Reach out to your Facilitator and they will help you here. Reach out to the Qwiklabs Chat support via the Qwiklabs home page. (You will need to click on the help button "?" alongside your profile icon on the top right corner when logged-in in order to access chat support) Just drop an email to dscsupport@qwiklabs.com.'
   },
   {
     question: 'We need to save credentials given in left side after clicking start button of labs?',
     answer: 'No they are temporary credentials through which you can access google console resources till that labs they will change in every labs. '
   },
   {
     question: 'How to know whether we have completed the lab or not?',
     answer: 'Tracked labs which have tracker in right side. (Marks for each task) For those lab you can end the lab as soon as you reaches to 100/100. Non tracked labs you can end these lab after 5 mins.'
   },
   {
     question: 'How to know whether this skill badge is completed or not?',
     answer: 'On completion of the full quest that quest will be automatically added to your public profile, sometime it takes 20-30 mins to get updated on your profile.'
   },
   {
     question: 'How can we tell or show others that we have done this badge?',
     answer: 'You can add your public profile link anywhere required.'
   },
];

export default questions