import React, { Component } from "react";
import axios from "axios";
import {
  Container,
  FormContainer,
  FormHeading,
  CustomName,
  SocialHandlesContainer,
  Role,
  ContactForm,
  UserInput,
  InputLabel,
  InputContainer,
  SubmitButton,
  UserMessage,
  SocialList,
  SocialLink,
  SocialIcon,
  SocialText,
} from "./Contact.style";

import facebook from "./images/facebook.png";
import instagram from "./images/instagram.png";
import linkedin from "./images/linkedin.png";
import github from "./images/github.png";
import Swal from "sweetalert2";

export default class ContactComponent extends Component {
  state = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  //   Handling Submit
  handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post(
        "https://the30daysofgooglecloud.herokuapp.com/contac"
      );
      if (data.mail === "Success") {
        Swal.fire({
          title: "Success",
          text: "Your query has been submitted.",
          icon: "success",
        });
        this.setState({ name: "", email: "", phone: "", message: "" });
      }
    } catch (error) {
      Swal.fire({
        title: "Something Went Wrong",
        text: "Contact kushdeepwalia@gmail.com.",
        icon: "error",
      });
    }
  };
  render() {
    return (
      <Container>
        <FormHeading>Contact Facilitator</FormHeading>
        <FormContainer>
          <SocialHandlesContainer>
            <CustomName>Kushdeep Walia</CustomName>
            <Role>Google Cloud Facilitator GDSC-GTBIT</Role>
            <SocialList>
              <SocialLink href="#">
                <SocialIcon src={github} />
                <SocialText> KushdeepWalia</SocialText>
              </SocialLink>
              <SocialLink href="#">
                <SocialIcon src={linkedin} />
                <SocialText> KushdeepWalia</SocialText>
              </SocialLink>
              <SocialLink href="#">
                <SocialIcon src={instagram} />
                <SocialText> KushdeepWalia</SocialText>
              </SocialLink>
              <SocialLink href="#">
                <SocialIcon src={facebook} />
                <SocialText> KushdeepWalia</SocialText>
              </SocialLink>
            </SocialList>
          </SocialHandlesContainer>
          <ContactForm method="POST" onSubmit={this.handleSubmit}>
            <InputContainer>
              <InputLabel>Name</InputLabel>
              <UserInput
                required
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
                name="name"
                placeholder="Enter your name"
              />
            </InputContainer>

            <InputContainer>
              <InputLabel>Email</InputLabel>
              <UserInput
                required
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                name="email"
                placeholder="Enter email id"
              />
            </InputContainer>

            <InputContainer>
              <InputLabel>Phone Number</InputLabel>
              <UserInput
                required
                value={this.state.phone}
                onChange={(e) => this.setState({ phone: e.target.value })}
                name="phone"
                pattern="[0-9]+"
                minLength="10"
                maxLength="10"
                title="Please enter number without country code"
                placeholder="Enter your phone number"
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>Query</InputLabel>
              <UserMessage
                style={{ resize: "none" }}
                required
                value={this.state.message}
                onChange={(e) => this.setState({ message: e.target.value })}
                name="message"
                rows="3"
                placeholder="Write your message here..."
              />
            </InputContainer>
            <SubmitButton type="submit">Send Query</SubmitButton>
          </ContactForm>
        </FormContainer>
      </Container>
    );
  }
}
