import styled from "styled-components";

export const Anchor = styled.a`
   color: black;
   text-decoration: none;

   &:hover{
      padding: 5px 0;
      border-bottom: 3px dotted black;
   }
   
   @media (max-width: 520px){
      &:hover{
         padding: 0;
      }
   }
`