import styled, { keyframes } from "styled-components"

export const LoaderContainer = styled.div`
    /* background-color: none; */
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 1;
`

const MoveUpDown = keyframes`
    0% {
        top: 0;
    }

    50% {
        top: -30px;
    }

    100% {
        top: 0;
    }
`

export const Circle = styled.div`
    z-index: 10;

    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    animation-name: ${MoveUpDown};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-delay: ${props => props.delay};
    background-color: ${props => props.color};
    position: relative;
    margin: 5px;
`

// .red {
//     background-color: #ea4335;
// }

// .blue {
//     animation-delay: 0.3s;
//     background-color: #4285f4;
// }

// .yellow {
//     animation-delay: 0.6s;
//     background-color: #fbbc05;
// }

// .green {
//     animation-delay: 0.9s;
//     background-color: #34a853;
// }

// @keyframes moveupdown {
    
// }