import axios from "axios";
import React, { Component } from "react";
import {
  Column,
  LeaderBoardContainer,
  NoDataMessage,
  RecordContainer,
  RecordHeading,
  RecordItem,
  RecordRow,
} from "./Leaderboard.style";
import { Heading } from "../common/Common.components";
import Loader from "../Loader/Loader.component";

export default class LeaderboardComponent extends Component {
  state = {
    isLoading: true,
    data: {
      both: [],
      track1: [],
      track2: [],
    },
  };

  componentDidMount() {
    const getLeaderboardData = async () => {
      const apiCal = await axios.get(
        "https://the30daysofgooglecloud.herokuapp.com/leaderboard"
      );

      this.setState({ data: apiCal.data, isLoading: false });
    };
    getLeaderboardData();
  }

  render() {
    const both = [...this.state.data.both];
    const track1 = [...this.state.data.track1];
    const track2 = [...this.state.data.track2];

    return this.state.isLoading ? (
      <NoDataMessage isLoading>
        <Loader />
      </NoDataMessage>
    ) : (
      <LeaderBoardContainer>
        <Heading>Leaderboard</Heading>
        {both.length === 0 && track1.length === 0 && track2.length === 0 ? (
          <h3 style={{ textAlign: "center" }}> No Data Available</h3>
        ) : (
          <RecordContainer>
            <RecordRow>
              <Column>
                <RecordHeading>Completed Both Tracks ({both.length})</RecordHeading>
                {both.length === 0 ? (
                  <NoDataMessage>
                    No participant has yet completed both of the tracks
                  </NoDataMessage>
                ) : (
                  this.state.data.both.map((item, index) => {
                    item = item.toLowerCase();
                    return <RecordItem key={index}>{item}</RecordItem>;
                  })
                )}
              </Column>
              <Column>
                <RecordHeading>Completed Track 1 ({track1.length})</RecordHeading>
                {track1.length === 0 ? (
                  <NoDataMessage>
                    {" "}
                    No participant has yet completed this track
                  </NoDataMessage>
                ) : (
                  this.state.data.track1.map((item, index) => {
                    item = item.toLowerCase();
                    return <RecordItem key={index}>{item}</RecordItem>;
                  })
                )}
              </Column>
              <Column>
                <RecordHeading>Completed Track 2 ({track2.length})</RecordHeading>
                {track2.length === 0 ? (
                  <NoDataMessage>
                    No participant has yet completed this track
                  </NoDataMessage>
                ) : (
                  this.state.data.track2.map((item, index) => {
                    item = item.toLowerCase();
                    return <RecordItem key={index}>{item}</RecordItem>;
                  })
                )}
              </Column>
            </RecordRow>
          </RecordContainer>
        )}
      </LeaderBoardContainer>
    );
  }
}
