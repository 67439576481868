import React, { Component } from "react";
import * as tag from "./Report.style";
import { Container, SubContainer, LeftContainer, RightContainer, RightImg, LeftHead, LeftDesc } from "../Home/Home.style"
import Swal from "sweetalert2";
import HomeImage from "../../assets/reportImage.png";
import { Heading } from "../common/Common.components";

export default class ReportComponent extends Component {
  constructor(props) {
    const api = "https://the30daysofgooglecloud.herokuapp.com/";
    // const api = "http://localhost:3000/";
    super(props);
    this.state = {
      loading: true,
      prevEmail: "",
      email: "",
      timeStamp: "",
      result: {},
      submitted: false,
    };

    this.lastUpdatedDate = () => {
      fetch(api + "lastupdated")
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            timeStamp: data
          });
        })
        .catch((err) => console.error(err));
    };

    this.updateState = () => {
      fetch(api + "report", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email: this.state.email,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.setState({ submitted: false });
          if (data.length !== 0) {
            if (data.error !== "Email not found") {
              return Swal.fire({
                title: data.StudentName,
                text: "Quests Completed in: ",
                icon: "success",
                showCancelButton: true,
                confirmButtonText:
                  "Track 1: " + data.CompletedInTrack1 + " Quests",
                cancelButtonText:
                  "Track 2: " + data.CompletedInTrack2 + " Quests",
              });
            } else {
              this.setState({
                submitted: true
              });
              return Swal.fire({
                title: "There was a error.",
                text: "Please contact us at - kushdeepwalia@gmail.com about this issue",
                icon: "warning",
              });
            }
          } else {
            return Swal.fire({
              title: "There was a error.",
              text: "Please contact us at - kushdeepwalia@gmail.com about this issue",
              icon: "warning",
            });
          }
        })
        .catch((err) => console.error(err));
    };
  }

  componentDidMount() {
    this.lastUpdatedDate();
  }

  render() {
    return (
      <Container>
        <Heading>Generate your Report</Heading>
        <SubContainer>
          <LeftContainer>
            <LeftHead>My Report</LeftHead>
            <LeftDesc>
              Last Updated on:{" "}
              <span key={this.state.timeStamp}>{this.state.timeStamp}.</span>
              <br />
              <br />
              To know more about the programme visit the website.
            </LeftDesc>
            <tag.InputContainer>
              <tag.InPlace>
                <tag.Input
                  type="email"
                  required
                  name="email"
                  placeholder="Email"
                  onBlur={(e) => {
                    if (this.state.email !== e.currentTarget.value) {
                      this.setState({
                        prevEmail: this.state.email,
                        email: e.currentTarget.value,
                        submitted: false
                      });
                    }
                    else {
                      this.setState({
                        submitted: true
                      });
                    }
                  }}
                />
                <tag.Place>Email</tag.Place>
              </tag.InPlace>
              <tag.Btn
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  if (this.state.email === "") {
                    return Swal.fire({
                      title: "Whoops!!",
                      text: "Please fill all fields",
                      icon: "warning",
                    });
                  }
                  if (this.state.submitted !== true && this.state.email !== this.state.prevEmail) {
                    this.updateState();
                  }
                }}
              >
                Get Report
              </tag.Btn>
            </tag.InputContainer>
          </LeftContainer>
          <RightContainer>
            <RightImg src={HomeImage} />
          </RightContainer>
        </SubContainer>
      </Container>
    );
  }
}
