import { Header } from "../Header/Header.styled"
import React from 'react'
import { Anchor } from "./Footer.style"

const Footer = () => {
   return (
      <Header border="up" >
         <span>Made by&nbsp;</span><Anchor href="https://kushdeepwalia.tk/" target="_blank">Kushdeep Walia</Anchor><span>, Google Cloud Facilitator 2021</span>
      </Header>
   )
}

export default Footer

