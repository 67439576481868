import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 50vh;
  padding: 2rem 6.25rem 3.5rem 6.25rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1000px){
    padding: 2rem 3rem;
  }
  @media (max-width: 520px){
    padding: 1.5rem 1rem;
  }
`;

export const SubContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  
  @media (max-width: 1000px){
    align-items: center;
  }
  @media (max-width: 750px){
    flex-direction: column-reverse;
  }
`;

export const LeftContainer = styled.div`
  width: 50%;
  height: 80%;

  flex-direction: column;
  display: flex;
  justify-content: space-around;

  @media (max-width: 750px){
    width: 90%;
  }
`;

export const LeftHead = styled.div`
  font-size: 22px;
  color: #0185fd;
  margin-bottom: 1.1rem;

  @media (max-width: 750px){
    text-align: center;
  }
`;

export const LeftDesc = styled.div`
  font-size: 1.1rem;
  line-height: 1.4;
  
  @media (max-width: 750px){
    text-align: center;
  }
`;

export const RightContainer = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 1000px){
    width: 50%;
  }
  @media (max-width: 750px){
    width: 70%;
    margin-bottom: 2rem;
  }
`;

export const RightImg = styled.img`
  width: 70%;
  border: 1px solid #b1b1b1;
  height: 80%;
  
  @media (max-width: 520px){
    width: 90%;
  }
  @media (max-width: 380px){
    width: 110%;
  }
`;
