import React from "react";
import { Container } from "../Contact/Contact.style";
import questions from "../../datafiles/FAQs";
import * as tag from "./FreqAskQues.style";
import { Heading } from "../common/Common.components";

const FreqAskQuesComponent = () => {
  return (
    <Container>
      <Heading>Frequently Asked Questions</Heading>
      <tag.SubHeading>Qwiklabs Queries</tag.SubHeading>
      <tag.QuestionContainer>
        {questions.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <tag.Question>{item.question}</tag.Question>
              <tag.Answer>{item.answer}</tag.Answer>
            </React.Fragment>
          );
        })}
      </tag.QuestionContainer>
    </Container>
  );
};

export default FreqAskQuesComponent;
