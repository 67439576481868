import React, { useRef, useState } from "react";
import * as tag from "./Header.styled";
import icon from "../../assets/NavLogo.png";
import heroImage from "../../assets/BannerImg.png";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

const Header = () => {
  const navbarRef = useRef();
  const History = useHistory();
  const [isHidden, setHidden] = useState(true);
  const [path, setPath] = useState(History.location.pathname.split("/")[1]);

  const handleNavBar = () => {
    if (isHidden) {
      navbarRef.current.style.height = "271px";
      setHidden(false);
    } else {
      navbarRef.current.style.height = "0";
      setHidden(true);
    }
  };
  return (
    <>
      <tag.Header>
        <tag.HeadIcon src={icon} />
        <tag.NavButton src="/icons/menu.png" alt="" onClick={handleNavBar} />
      </tag.Header>
      <tag.Hero backgroundImage={heroImage} />
      <tag.NavBar>
        <tag.NavBarList ref={navbarRef}>
          <tag.NavBarItem color="" page={path}>
            <Link id="home" to="/" onClick={() => setPath("")}>
              Home
            </Link>
          </tag.NavBarItem>
          <tag.NavBarItem color="report" page={path}>
            <Link id="report" to="/report" onClick={() => setPath("report")}>
              My Report
            </Link>
          </tag.NavBarItem>
          <tag.NavBarItem color="leaderboard" page={path}>
            <Link
              id="leaderboard"
              onClick={() => setPath("leaderboard")}
              to="/leaderboard"
            >
              Leaderboard
            </Link>
          </tag.NavBarItem>
          <tag.NavBarItem color="faq" page={path}>
            <Link id="faq" to="/faq" onClick={() => setPath("faq")}>
              FAQs
            </Link>
          </tag.NavBarItem>
          <tag.NavBarItem color="contact" page={path}>
            <Link id="contact" to="/contact" onClick={() => setPath("contact")}>
              Contact Facilitator
            </Link>
          </tag.NavBarItem>
        </tag.NavBarList>
      </tag.NavBar>
      <tag.HeroMobile backgroundImage={heroImage} />
    </>
  );
};

export default Header;
