import styled from "styled-components";

export const Header = styled.div`
  height: 10vh;
  width: 100%;
  ${(props) =>
    props.border === "up"
      ? "border-top: 1px solid #ADADAD;"
      : "border-bottom: 1px solid #ADADAD;"}
  ${(props) => (props.border === "up" ? "justify-content: center;" : "")}
   ${(props) => (props.border === "up" ? "font-size: 1.2rem;" : "")}
   ${(props) => (props.border === "up" ? "color: #858585;" : "")}
   display: flex;
  align-items: center;
  ${(props) => (props.border === "up" ? "" : "padding-left: 3.5rem;")}

  @media (max-width: 520px) {
    ${(props) =>
      props.border === "up"
        ? "flex-direction: column; padding: 1rem 0; height: calc(10vh + 2rem)"
        : ""}

    justify-content: space-between;
    padding: 0 2rem;
  }
  @media (max-width: 330px) {
    ${(props) => (props.border === "up" ? "" : "padding-left: 1.5rem;")}
  }
`;

export const NavButton = styled.img`
  width: 30px;
  height: 30px;
  display: none;

  @media (max-width: 520px) {
    display: block;
  }
`;

export const HeadIcon = styled.img`
  margin-top: 0.5rem;
`;

export const Hero = styled.div`
  height: 55vh;
  width: 100%;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;

  @media (max-width: 520px) {
    display: none;
  }
`;

export const HeroMobile = styled(Hero)`
  display: none;

  @media (max-width: 520px) {
    display: block;
  }
`;

export const NavBar = styled.div`
  width: 100%;
  height: 7vh;
  display: flex;
  align-items: center;
  padding-left: 30px;
  border-bottom: 1px solid #adadad;

  @media (max-width: 520px) {
    height: auto;
  }
`;

export const NavBarList = styled.ul`
  display: flex;
  transition: all 300ms ease-in;
  overflow: hidden;

  @media (max-width: 520px) {
    flex-direction: column;
    height: 0;
  }
`;

export const NavBarItem = styled.li`
  list-style-type: none;
  padding: 25px;
  font-size: 1.2rem;

  & a {
    text-decoration: none;
    ${(props) =>
      props.page === props.color ? "color: #0185FD" : "color: #858585"}
  }

  @media (max-width: 520px) {
    padding: 1rem;
  }
`;
