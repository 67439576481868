import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'
import HomeComponent from "../../components/Home/Home.component"

const Home = props => {
   return (
      <>
         <HomeComponent />
      </>
   )
}

Home.propTypes = {

}

export default Home
