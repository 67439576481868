import styled from "styled-components";

// contact form container
export const Container = styled.div`
  padding: 2rem 6.25rem 3.5rem 6.25rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    padding: 2rem 3rem;
  }
`;

export const FormContainer = styled.div`
  display: flex;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

// Social Handles Container
export const SocialHandlesContainer = styled.div`
  flex: 1;

  @media (max-width: 550px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;
export const SocialList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SocialLink = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: "Raleway";
  margin: 20px 0 0 20px;
`;
export const SocialIcon = styled.img`
  width: 40px;
  height: 40px;
`;

export const SocialText = styled.span`
  font-size: 18px;
  margin-left: 10px;
  color: #0185fd;
`;

export const CustomName = styled.p`
  color: #0185fd;
  font-size: 22px;
  margin-bottom: 8px;
  font-family: "Raleway";
`;

export const Role = styled.p`
  color: #515151;
  font-size: 15px;
  margin-bottom: 20px;
  font-family: "Raleway";
`;
// heading
export const FormHeading = styled.p`
  font-size: 30px;
  color: #515151;
  font-family: "Raleway";
  margin-bottom: 2rem;

  text-align: center;
`;

// Contact Form
export const ContactForm = styled.form`
  flex: 1;
  padding-top: 50px;
`;

export const InputContainer = styled.div`
  position: relative;
`;
export const InputLabel = styled.label`
  font-size: 14px;
  top: 3px;
  left: 30px;
  background: white;
  padding: 1.5px 5px;
  color: #0185fd;
  position: absolute;
`;
export const UserInput = styled.input`
  border: 0.5px solid #aaa;
  border-radius: 2px;
  padding: 10px 15px;
  width: 90%;
  outline: none;
  font-size: 16px;
  margin: 15px;
  font-family: "Raleway";

  &::placeholder {
    color: lightgrey;
    /* padding-left: 2px; */
  }

  &:focus::placeholder {
    color: white;
  }

  &:focus ~ ${InputLabel} {
    background-color: white;
    padding: 1.5px 5px;
    display: inline;
    color: #0185fd;
    font-weight: 500;
  }

  &:internal-autofill-selected {
    background-color: red;
  }
`;

export const UserMessage = styled.textarea`
  border: 0.5px solid #aaa;
  border-radius: 2px;
  padding: 10px 15px;
  width: 90%;
  outline: none;
  font-size: 16px;
  margin: 15px;
  font-family: "Raleway";

  &::placeholder {
    color: lightgrey;
    /* padding-left: 2px; */
  }

  &:focus::placeholder {
    color: white;
  }

  &:focus ~ ${InputLabel} {
    background-color: white;
    padding: 1.5px 5px;
    display: inline;
    color: #0185fd;
    font-weight: 500;
  }
`;

export const SubmitButton = styled.button`
  background: #0185fd;
  padding: 10px 20px;
  color: white;
  margin: 20px 15px;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
`;
