import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 2rem 6.25rem 3.5rem 6.25rem;
  /* min-height: 50vh;
  max-height: 50vh; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
`;

export const SubContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  width: 50%;
  height: 80%;

  flex-direction: column;
  display: flex;
  justify-content: space-around;
`;

export const LeftHead = styled.div`
  font-size: 22px;
  color: #0185fd;
  margin-bottom: 8px;
`;

export const LeftDesc = styled.div`
  font-size: 1.1rem;
  margin-bottom: 25px;
`;

export const RightContainer = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const RightImg = styled.img`
  width: 70%;
  border: 1px solid #b1b1b1;
  height: 80%;
`;

export const InputContainer = styled.div`
  width: 100%;
  height: 45px;

  display: flex;
  align-items: flex-start;

  @media (max-width: 620px){
    flex-direction: column;
    height: 15vh;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
  }
`;

export const InPlace = styled.div`
  width: 70%;
  position: relative;
`;

export const Place = styled.span`
  font-size: 0.8rem;
  position: absolute;
  left: 20px;
  top: -10px;
  display: none;
`;

export const Input = styled.input`
  border: 0.5px solid #aaa;
  border-radius: 2px;
  padding: 10px 15px;
  width: 90%;
  outline: none;
  font-size: 16px;
  font-family: "Raleway";

  @media (max-width: 620px){
    width: 100%;
  }

  &::placeholder {
    color: lightgrey;
    /* padding-left: 2px; */
  }

  &:focus::placeholder {
    color: white;
  }

  &:focus ~ ${Place} {
    background-color: white;
    padding: 1.5px 5px;
    display: inline;
    color: #0185fd;
    font-weight: 500;
  }
`;

export const Btn = styled.button`
  background: #0185fd;
  padding: 10px 30px;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
`;
