import { BrowserRouter, Route, Switch } from "react-router-dom";
import React, { Component } from 'react'
import Header from "./components/Header/Header.component"
import Home from "./pages/Home/Home.page"
import Report from "./pages/Report/Report.page"
import Leaderboard from "./pages/Leaderboard/Leaderboard.page"
import FreqAskQues from "./pages/FreqAskQues/FreqAskQues.page"
import Contact from "./pages/Contact/Contact.page"
import Footer from "./components/Footer/Footer.component";

export default class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<Header />
				<Switch>
					<Route path="/" exact component={Home} />
					<Route path="/report" exact component={Report} />
					<Route path="/leaderboard" exact component={Leaderboard} />
					<Route path="/faq" exact component={FreqAskQues} />
					<Route path="/contact" exact component={Contact} />
				</Switch>
				<Footer />
			</BrowserRouter>
		);
	}
}

/*
import React, { Component } from 'react'

export default class App extends Component {
	render() {
		return (
			<div>

			</div>
		)
	}
}
*/