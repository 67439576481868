import styled from "styled-components";

export const SubHeading = styled.p`
  color: #0185fd;
  font-size: 22px;
  margin-bottom: 8px;
  font-family: "Raleway";
`;

export const QuestionContainer = styled.div``;

export const Question = styled.p`
  color: #515151;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 8px;
  margin: 10px 0;
  font-family: "Raleway";
`;

export const Answer = styled.p`
  color: #727272;
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 20px;
  font-family: "Raleway";
`;
