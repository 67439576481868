import ReportComponent from "../../components/Report/Report.component"
import React from 'react'

const Report = () => {
   return (
      <>
         <ReportComponent/>  
      </>
   )
}

export default Report

