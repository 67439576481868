import React from 'react'
import LeaderboardComponent from '../../components/Leaderboard/Leaderboard.component'
// eslint-disable-next-line
import PropTypes from 'prop-types'

const Leaderboard = props => {
   return (
      <>
         <LeaderboardComponent/>  
      </>
   )
}

Leaderboard.propTypes = {

}

export default Leaderboard
